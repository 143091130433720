import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import Transparent from './images/transparent.gif'
import './mobilelogin.css'
import axios from 'axios';
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify'



const cookies = new Cookies();

var cc;
var tt;
toast.configure()


export default function MobileLogin(props) {

	const [Socialicon, setSocialicon] = useState(0);
	const [captchaSuccess, setCaptcha] = useState(false);
	const [userid, setId] = useState("");
	const [password, setPassword] = useState("");

	function createCaptcha() {
		if (document.getElementById("captch")) {
			document.getElementById("captch").remove();
		}

		var captcha = [];
		while (captcha.length < 4) {
			//below code will not allow Repetition of Characters
			var index = Math.floor(Math.random() * 10); //get the next character from the array

			captcha.push(index);
		}
		var canv = document.createElement("canvas");
		canv.style.width = "inherit";
		canv.id = "captch";
		canv.width = 70;
		canv.height = 50;

		var ctx = canv.getContext("2d");
		ctx.font = "600 30px Arial";
		ctx.fillText(captcha.join(""), 0, 30);
		//storing captcha so that can validate you can save it somewhere else according to your specific requirements
		cc = captcha.join("");

		if (document.getElementById("popupcaptcha")) {
			document.getElementById("popupcaptcha").appendChild(canv);
		}
	}

	function validateCaptcha(event) {
		if (event.target.value === cc) {
			setCaptcha(true);
		} else {
			setCaptcha(false);
		}
	}

	useEffect(() => {
		createCaptcha();
	}, []);

	const handleLogin = () => {
		if (userid === "") {
			createCaptcha();
			document.getElementById("errorMsg").innerHTML = "Username is empty";

			return;
		}
		if (password === "") {
			createCaptcha();
			document.getElementById("errorMsg").innerHTML = "Password is empty";

			return;
		}

		if (!captchaSuccess) {
			createCaptcha();
			document.getElementById("errorMsg").innerHTML = "Captcha is not valid!";

			return;
		}

		var ssid = cookies.get("sid");

		axios
			.post("https://flagapi.in/agentapi/NewagentLogin", {
				id: userid,
				password: password,
			})
			.then((result) => {
				if (result.status === 200) {
					cookies.set("sid", result.data, { path: "/" });
					props.checkShowLogin(true);

					setCaptcha(false);

				}
				else if (result.status === 205) {
					setCaptcha(false);

					document.getElementById("errorMsg").innerHTML = "User blocked!";
					createCaptcha();
				}
				else {
					setCaptcha(false);
					document.getElementById("errorMsg").innerHTML = "Username or password incorrect!";
					createCaptcha();
				}
			})
			.catch((e) => {
				//setIsError(true);
			});
	};



	return (
		<React.Fragment>
			<div style={{
				color: 'rgb(29, 44, 56)', backgroundColor: 'rgb(29, 44, 56)', minHeight: '100vh', backgroundAttachment: 'fixed', backgroundImage:'linear-gradient(56deg, rgb(58, 88, 112) 4%, rgb(29, 44, 56) 42%)'
			}}>
				<header className="login-head-mobile">
					<Link to="/" className="close-mobile" style={{ padding: 0 }}></Link>
					<h1>maza247</h1>
				</header>
				<dl className="form-login-mobile" >
					<dd id="loginNameErrorClass" >
						<input type="email" value={userid} onChange={(e) => { setId(e.target.value) }} id="loginName" placeholder="Username" />
					</dd>
					<dd id="passwordErrorClass">
						<input type="password" value={password} onChange={(e) => { setPassword(e.target.value) }} id="password" placeholder="Password" />
					</dd>
					<dd id="validCodeErrorClass">
						<input type="text" onChange={(e) => { validateCaptcha(e) }} id="validCode" inputMode="numeric" placeholder="Validation" maxLength="4" />
						<div id="popupcaptcha" className="CaptchaPopup-mobile">
						</div>
					</dd>
					<dd>
						<a className="btn-send-mobile" onClick={() => { handleLogin() }} id="loginBtn">Login</a>
					</dd>
					<dd id="errorMsg" className="state-mobile"></dd>
				</dl>

				<div id="supportWrap" class="support-wrap-mobile">
					<div class="support-service-mobile">
						<a id="support_email"
							onClick={() => { setSocialicon(0) }}
							className={`support-mail-mobile  ${(Socialicon === 0) ? "open" : "null"}`}
						><img src={Transparent} title="Email" /></a>
						<a id="support_whatsapp"
							onClick={() => { setSocialicon(1) }}
							className={`support-whatsapp-mobile  ${(Socialicon === 1) ? "open" : "null"}`}
						><img src={Transparent} title="WhatsApp" /></a>
						<a id="support_telegram"
							onClick={() => { setSocialicon(2) }}
							className={`support-fb-mobile  ${(Socialicon === 2) ? "open" : "null"}`}
						><img src={Transparent} title="facebook" /></a>
						<a id="support_skype"
							onClick={() => { setSocialicon(3) }}
							className={`support-skype-mobile  ${(Socialicon === 3) ? "open" : "null"}`}
						><img src={Transparent} title="Skype" /></a>
						<a id="support_instagram"
							onClick={() => { setSocialicon(4) }}
							className={`support-ig-mobile  ${(Socialicon === 4) ? "open" : "null"}`}>
								<img src={Transparent} title="Instagram" /></a>
					</div>
					<div class="support-info-mobile">
						{Socialicon === 0 &&
							<div id="supportDetail_email"
								className={`support-detail-mobile ${(Socialicon === 0) ? "open" : "null"}`}
							><a ><span>info@maza247.live</span></a></div>
						}
						<div id="supportDetail_whatsapp" className={`support-detail-mobile ${(Socialicon === 1) ? "open" : "null"}`}>
							{Socialicon === 1 &&
								<a href="http://Wa.me/33753540743" class="ui-link"><span>Wa.me/33753540743</span></a>
							}

						</div>
						<div id="supportDetail_telegram" className={`support-detail-mobile ${(Socialicon === 2) ? "open" : "null"}`}>
							{Socialicon === 2 &&
								<a href="https://www.facebook.com/groups/maza247/" class="ui-link"><span>www.facebook.com/groups/maza247</span></a>
							}

						</div>
						<div id="supportDetail_skype" className={`support-detail-mobile ${(Socialicon === 3) ? "open" : "null"}`}>
							{Socialicon === 3 &&
								<a ><span>maza247official</span></a>
							}
						</div>
						<div id="supportDetail_instagram" className={`support-detail-mobile ${(Socialicon === 4) ? "open" : "null"}`}>
							{Socialicon === 4 &&

								<a ><span>officialmaza247</span></a>
							}
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}
