import React, { useState,useEffect } from 'react'
import Loading from "./images/loading40.gif"
import './Fullmarket.css'
import Transparent from './images/transparent.gif'
import Cookies from 'universal-cookie';
import Axios from 'axios'
import moment from 'moment'
import DownlineLive from './DownlineLive'
import DeclaredFancy from './DeclaredFancy'
import FancyBook from "./FancyBook";
require('moment-timezone');

const io = require('socket.io-client');

const cookies = new Cookies();

const ENDPOINT = "https://flagapi.in:2087";
var socket;


var c1 = 0,c2 =0,c3 = 0, c4 = 0, c5 = 0, c6 = 0,c7 = 0, c8 = 0, c9 = 0, c10 = 0, c11 = 0, c12 = 0;
var s1,s2,s3,s4,s5,s6,s7,s8,s9,s10,s11,s12,s13,s14,s15,s16,s17,s18,s19,s20,s21,s22,s23,s24;


export default function Runningmarketanlysis() {
    
    const [runner1BackRate1,setrunner1BackRate1] = useState(' ');
    
    const [runner1LayRate1,setrunner1LayRate1] = useState(' ');
   
    const [runner2BackRate1,setrunner2BackRate1] = useState(' ');
    
    const [runner2LayRate1,setrunner2LayRate1] = useState(' ');
    const [tieBackRate1,settieBackRate1] = useState(' ');
    const [tieLayRate1,settieLayRate1] = useState(' ');
    
    const [marketData, setmarketData ] = useState({})
    const [bookData2, setbookData2] = useState({})
    const [bookData, setbookData] = useState({})
    const [fancyData, setfancyData ] = useState([])
    const [marketBetStatus,setmarketBetStatus] = useState(0);
    const [showDownline,setshowDownline] = useState(false);
    const [popup,setpopup] = useState(null);


    const [marketpnl1,setmarketpnl1] = useState(0);
    const [marketpnl2,setmarketpnl2] = useState(0);
    const [marketpnl3,setmarketpnl3] = useState(0);
    const [bookpnl1,setbookpnl1] = useState(0);
    const [bookpnl2,setbookpnl2] = useState(0);
    const [bookpnl3,setbookpnl3] = useState(0);
    const [fancySelection,setfancySelection] = useState(null);
    const [fancyName,setfancyName] = useState('');
    const [refresh,setrefresh] = useState(true);
    

   

    
    const url = window.location.href;
    const para = url.split( '/' );

    const eventid = para[4];
   

    const changeDownlineLive = ()=>{
        setshowDownline(false);
        setpopup(null);
        setfancySelection(null);
        setfancyName('');

	}
  

    const getProfitLoss = ()=>{
        var ssid = cookies.get('sid');
        
        if(!ssid) return;
        if(!eventid) return;
       
        if(marketData.marketId){
         Axios.post(' https://flagapi.in/agentapi/newagentMarketExposure',{
           sid:ssid,
           eventId:eventid,
           marketId:marketData.marketId
         
           })
           .then(result => {
              
            if(refresh) {setrefresh(false);}
            if(result.status === 200){

                setmarketpnl1(result.data.teamA_total);
                setmarketpnl2(result.data.teamB_total);
                setmarketpnl3(result.data.draw_total);

            
             }   
   
            })
             .catch(e => {
        
          });
        }

        if(bookData2.marketId){
          Axios.post(' https://flagapi.in/agentapi/newagentMarketExposure',{
            sid:ssid,
            eventId:eventid,
            marketId:bookData2.marketId
          
            })
            .then(result => {
              
                if(refresh) {setrefresh(false);}
                if(result.status === 200){

                    setbookpnl1(result.data.teamA_total);
                    setbookpnl2(result.data.teamB_total);
                    setbookpnl3(result.data.draw_total);
    
         
                }       
        
             })
              .catch(e => {
         
         });
       
        }

	}



    useEffect(() => {
        socket = io(ENDPOINT);
        console.log('socket connected');
        socket.on('connect', function(data) {
            socket.emit('room1', eventid);
        });
        
        return () => {
        socket.close();
        console.log('socket disconnected');
        s1 = -1;s2 = -1;s3=-1;s4=-1;s5=-1;s6=-1;s7=-1;s8=-1;s9=-1;s10=-1;s11=-1;s12=-1;s13=-1;s14=-1;s15=-1;s16=-1;s17=-1;s18=-1;s19=-1;s20=-1;s21=-1;s22=-1;s23=-1;s24=-1;
        }
      }, [])


    useEffect(() => {
         getProfitLoss(); 
         
         const intervalId = setInterval(() => {
           getProfitLoss();       
          }, 5000);
          
         
          return () => clearInterval(intervalId); 
          
       }, [marketData,bookData2,eventid])


    useEffect(() => {

     
        if(!eventid) return; 
   
         Axios.post('https://flagapi.in/clientapi/getFullMarket',{
          
           eventId:eventid,
         
           })
           .then(result => {
             
               
              if(result.data[0]){
               let obj = result.data[0]; 
               setrunner1BackRate1(obj.runner1BackRate1); 
               setrunner1LayRate1(obj.runner1LayRate1);
               setrunner2BackRate1(obj.runner2BackRate1);
               setrunner2LayRate1(obj.runner2LayRate1);
               if(obj.selectionIdTie){
                   settieBackRate1(obj.tieBackRate1);
                   settieLayRate1(obj.tieLayRate1);
               }
   
   
               setmarketData(obj);
              }
   
            })
             .catch(e => {
        
          });

          
          Axios.post('https://flagapi.in/clientapi/getBookMakerMarket',{
          
           eventId:eventid,
         
           })
           .then(result => {
             
               
              if(result.data[0]){   
               let obj = result.data[0];   
               setbookData2(obj);
              }
   
              
       
            })
             .catch(e => {
        
          });
        },[]);
   
       
        useEffect(() => {
            
            socket.on(eventid,(val)=>{
               
                var value = JSON.parse(val);
                
                if(value.messageType === 'betfair_match_rate'){
                    setmarketBetStatus(value.status);
                    
                    
                    if(s1 !== value.runner1BackRate1  ){
                        setrunner1BackRate1(value.runner1BackRate1);
                        s1 = value.runner1BackRate1;
                        c1 = 1;
                        setTimeout(()=>{c1 = 0;},  700);}
                   
                   
                   
                   
                   if(s3 !== value.runner1LayRate1  ){      
                        setrunner1LayRate1(value.runner1LayRate1);
                        s3 = value.runner1LayRate1;
                        c2 = 1;
                        setTimeout(()=>{c2 = 0;},  700);}
                  
                 
                  
                   if(s5 !== value.runner2BackRate1  ){ 
                       setrunner2BackRate1(value.runner2BackRate1);
                        s5 = value.runner2BackRate1;
                        c3 = 1;
                        setTimeout(()=>{c3 = 0;},  700);}
                   
                  
                   
                   if(s7 !== value.runner2LayRate1  ){          
                        setrunner2LayRate1(value.runner2LayRate1);
                        s7 = value.runner2LayRate1;
                        c4 = 1;
                        setTimeout(()=>{c4 = 0;},  700);}
                   
                   
    
                   if(s9 !== value.tieBackRate1  ){ 
                        settieBackRate1(value.tieBackRate1);
                        s9 = value.tieBackRate1;
                        c5 = 1;
                        setTimeout(()=>{c5 = 0;},  700);
                       }
                   
                   if(s11 !== value.tieLayRate1 ){ 
                        settieLayRate1(value.tieLayRate1);
                        s11 = value.tieLayRate1;
                        c6 = 1;
                        setTimeout(()=>{c6 = 0;},  700);
                       }
    
                   
                              
                   }
    
    
                if(value[0] && value[0].messageType === 'session_rate'){
                    let temp = value;
                    temp.sort(function(a,b){
              
                        return moment(a.selectionId) - moment(b.selectionId);
                       }); 
                    
                    setfancyData(temp);
                   
    
                }
               
                if(value.messageType === 'bookmaker_match_rate'){
                   
                    let temp = bookData;
                    
                    temp.gameStatus = value.status;
                    temp.runner1Status = value.runner1Status;
                    temp.runner2Status = value.runner2Status;
                    temp.runner3Status = value.runner3Status;
                    temp.runner1BackRate1 = value.runner1BackRate1;
                    temp.runner1LayRate1 = value.runner1LayRate1;
                    temp.runner2BackRate1 = value.runner2BackRate1;
                    temp.runner2LayRate1 = value.runner2LayRate1;
    
                  
                    temp.tieBackRate1 = value.tieBackRate1;
                    temp.tieLayRate1 = value.tieLayRate1;
                  
      
                    setbookData(temp);
    
                  }  
                 })
                  return ()=>{
                    socket.removeAllListeners(eventid);
                    setrunner1BackRate1(' ');setrunner2BackRate1(' ');
                    setrunner1LayRate1(' ');setrunner2LayRate1(' ');
                    
                    setfancyData([]);
                 }
        
            
        
          },[])


        
        
      return (
        <React.Fragment>
            <div class="full-wrap">
         {/* <!-- Left Column --> */}
      <div class="col-left" style={{display:'block', top:'0'}}>
             {/* <!-- Sub Menu and Path --> */}
             <div id="subMenu" class="sub_path" style={{height: 'calc(100% - 0px)'}}>
             </div>
         </div>
         
         {/* <!-- index start -->
         <!-- Center Column --> */}
         <div id="centerColumn" class="col-center markets" >
         
         {/* <!-- Loading Wrap --> */}
         {refresh && <div id="loading" class="loading-wrap" style={{}}>
		<ul class="loading">
			<li>
				<img src={Loading}/>
			</li>
			<li>Loading...</li>
		   </ul>
	      </div>}
         
             <div id="overWrap" class="over-wrap live-match" style={{height: 'calc(100% - 0px)'}}>
                 <div id="liveMatchGameHead" class="game-head">            
                     <div id="matchTrackerWrap" class="match-tracker-wrap">  
                         <ul id="liveMatchTrackerBtn" class="match-btn">
                             <li><a id="liveMultiMarketPin" style={{cursor:'pointer'}} name="gameHeadPin" class="btn-pin" title="Add to Multi Markets" eventtype="4" eventid="30194778" marketid="1.177132011"></a></li>
                             <li><a style={{cursor:'pointer'}} class="btn-refresh"></a></li>
                         </ul>
                     </div>
                     {/* <!-- Game Scroes Event --> */}
                     <div id="scoresEvent" class="match-odds">{marketData.eventName ? marketData.eventName : bookData2.eventName}<img style={{color:'green'}} src={Transparent}/></div>
                     
                     {/* <!-- Game Information --> */}
                     <ul class="game-info" style={{display:'none'}}>
                         <li id="gameInfoDate" class=""><img class="icon-time" style={{display:'none'}} src={Transparent}/> Tue 22 Dec, 11:30</li>
                         <li><span id="lowLiquidityTag" class="game-low_liq" style={{display:'none'}}>Low Liquidity</span></li> 
                     </ul>
                     <dl class="game-matched" style={{display:'block'}}>
                       <dd id="gameInfoDate" class=""><img class="icon-time" src={Transparent}/>{moment.tz(marketData.marketStartTime,'YYYY-MM-DD HH:mm:ss','Asia/Kolkata').local().format('lll')}</dd>
                         
                     </dl>
                     
                 </div>
         
                 {/* <!-- Game Bets Table --> */}
                  <div id="bookMakerWrap" class="bets-wrap  bookmaker_bet" >
         <table id="bookMakerMarketList" class="bets bets-bookmaker" >
         <colgroup>
             <col span="1" width="280"/>
             <col span="1" width="70"/>
             <col span="1" width="70"/>
             <col span="1" width="70"/>
             <col span="1" width="70"/>
             <col span="1" width="70"/>
             <col span="1" width="70"/>
         </colgroup>

        {marketData.matchStatus == 1 && 
         <tbody id="bookMakerMarket_30194778_66628" style={{display: 'table-row-group'}}>
              <tr id="bookMakerSpecialBet" class="special_bet">
                 <td colspan="7">
                     <h3>
                     <a id="multiMarketPin" class="add-pin" title="Add to Multi Markets">Add Pin</a>
                     Match Odds
                     <p></p>
                     <dl class="fancy-info">
                        <a onClick = {()=>{setshowDownline(true);setpopup(1);}}  style ={{ padding: "0px 10px"}} id="marketDownline" class="btn-send">View Downline</a>
                      </dl>
                     
                     </h3>
                     
                 </td>
             </tr>
             <tr class="bet-all">
                 <td colspan="1"></td>
                 {/* <!-- <td class="refer-bet"></td> --> */}
                 <td colspan="3">Back</td>
                 <td colspan="3">Lay</td>
                 {/* <td class="refer-book" colspan="2"></td> */}
             </tr>

            {marketBetStatus != 1 && <tr id="bookMakerSuspend_30194778_66628_197444" class="fancy-suspend-tr"  marketid="66628">
                 <th colspan=""></th>
                 <td class="fancy-suspend-td" colspan="6">
                     <div id="suspendClass" class="fancy-suspend"><span id="info">Suspend</span></div>
                 </td>
             </tr>}

             <tr id="bookMakerSelection_30194778_66628_197444" style={{display: 'table-row'}}>
                 <th class="predict">
                     <p id="runnerName">{marketData.runnerName1}</p>
                     <ul >
                     {<li id="before" style = {{fontSize:'12px'}} className={`${marketpnl1 >= 0 ? "win":"lose"}`} >{marketpnl1 >= 0 ? parseFloat(marketpnl1).toFixed(2) : '('+ parseFloat(Math.abs(marketpnl1)).toFixed(2) +')'}</li>}
                     </ul>
                    
                </th>
                      
                 <td class="" colspan="3">
                     <dl class="back-gradient">
                         <dd id="back_1" style={{cursor:'pointer'}} className={`back-1 ${(c1=== 1 )? "spark": "null"}`}><a>{runner1BackRate1}</a></dd>
                     </dl>
                 </td>
                 <td class="" colspan="3">
                     <dl class="lay-gradient">
                         <dd id="lay_1" style={{cursor:'pointer'}} className={`lay-1 ${(c2=== 1 )? "spark": "null"}`}><a>{runner1LayRate1}</a></dd>
                     </dl>
                 </td>
             </tr>

            {marketBetStatus != 1 && <tr id="bookMakerSuspend_30194778_66628_197445" class="fancy-suspend-tr"  marketid="66628">
                 <th colspan=""></th>
                 <td class="fancy-suspend-td" colspan="6">
                    <div id="suspendClass" class="fancy-suspend"><span id="info">Suspend</span></div>
                 </td>
             </tr>}
             <tr id="bookMakerSelection_30194778_66628_197445" style={{display: 'table-row'}}>
                 <th class="predict">
                     <p id="runnerName">{marketData.runnerName2}</p>
                     <ul >
                     {<li id="before" style = {{fontSize:'12px'}} className={`${marketpnl2 >= 0 ? "win":"lose"}`} >{marketpnl2 >= 0 ? parseFloat(marketpnl2).toFixed(2) : '('+ parseFloat(Math.abs(marketpnl2)).toFixed(2) +')'}</li>}
                     </ul>
                 </th>
                 <td class="" colspan="3">
                     <dl class="back-gradient">
                         <dd id="back_1" style={{cursor:'pointer'}} className={`back-1 ${(c3=== 1 )? "spark": "null"}`}><a>{runner2BackRate1}</a></dd>
                     </dl>
                 </td>
                 <td class="" colspan="3">
                     <dl class="lay-gradient">
                         <dd id="lay_1" style={{cursor:'pointer'}} className={`lay-1 ${(c4=== 1 )? "spark": "null"}`}><a>{runner2LayRate1}</a></dd>
                     </dl>
                 </td>
             </tr>


         {marketData.selectionIdTie  && marketData.selectionIdTie != ' ' &&  <React.Fragment>
           {marketBetStatus != 1 && <tr id="bookMakerSuspend_30194778_66628_197445" class="fancy-suspend-tr"  marketid="66628">
                 <th colspan=""></th>
                 <td class="fancy-suspend-td" colspan="6">
                    <div id="suspendClass" class="fancy-suspend"><span id="info">Suspend</span></div>
                 </td>
            </tr>}

             <tr id="bookMakerSelection_30194778_66628_197445" style={{display: 'table-row'}}>
                 <th class="predict">
                     <p id="runnerName">The Draw</p>
                     <ul >
                     {<li id="before" style = {{fontSize:'12px'}} className={`${marketpnl3 >= 0 ? "win":"lose"}`} >{marketpnl3 >= 0 ? parseFloat(marketpnl3).toFixed(2) : '('+ parseFloat(Math.abs(marketpnl3)).toFixed(2) +')'}</li>}
                     </ul>
                 </th>
                 <td class="" colspan="3">
                     <dl class="back-gradient">
                         <dd id="back_1" style={{cursor:'pointer'}} className={`back-1 ${(c5=== 1 )? "spark": "null"}`}><a>{tieBackRate1}</a></dd>
                     </dl>
                 </td>
                 <td class="" colspan="3">
                     <dl class="lay-gradient">
                         <dd id="lay_1" style={{cursor:'pointer'}} className={`lay-1 ${(c6=== 1 )? "spark": "null"}`}><a>{tieLayRate1}</a></dd>
                     </dl>
                 </td>
            </tr>
        </React.Fragment>}

            </tbody>}
            
          </table>
         </div>   
        
        
        


      {bookData2.matchStatus == 1  && 
        <div id="bookMakerWrap" class="bets-wrap  bookmaker_bet" >
         <table id="bookMakerMarketList" class="bets bets-bookmaker" >
         <colgroup>
             <col span="1" width="280"/>
             <col span="1" width="70"/>
             <col span="1" width="70"/>
             <col span="1" width="70"/>
             <col span="1" width="70"/>
             <col span="1" width="70"/>
             <col span="1" width="70"/>
          </colgroup>


    
         <tbody id="bookMakerMarket_30194778_66628" style={{display: 'table-row-group'}}>
             <tr id="bookMakerSpecialBet" class="special_bet">
                 <td colspan="7">
                     <h3>
                     <a id="multiMarketPin" class="add-pin" title="Add to Multi Markets">Add Pin</a>
                     Bookmaker Market
                     <p></p>
                     <dl class="fancy-info">
                        <a onClick = {()=>{setshowDownline(true);setpopup(2);}}  style ={{ padding: "0px 10px"}} id="marketDownline" class="btn-send">View Downline</a>
                      </dl>
                     </h3>
                     
                 </td>
             </tr>
             <tr class="bet-all">
                 <td colspan="1"></td>
                 {/* <!-- <td class="refer-bet"></td> --> */}
                 <td colspan="3">Back</td>
                 <td colspan="3">Lay</td>
                 {/* <td class="refer-book" colspan="2"></td> */}
             </tr>

             {(bookData.gameStatus != 2  ||  bookData.runner1Status == 2)  &&  <tr id="bookMakerSuspend_30194778_66628_197444" class="fancy-suspend-tr"  marketid="66628">
                 <th colspan=""></th>
                 <td class="fancy-suspend-td" colspan="6">
                     <div id="suspendClass" class="fancy-suspend"><span id="info">{bookData.gameStatus == 10 && bookData.runner1Status == 1? 'Ball Running' : 'Suspend'}</span></div> 
                 </td>
             </tr>}
             
            
             <tr id="bookMakerSelection_30194778_66628_197444" style={{display: 'table-row'}}>
                 <th class="predict">
                     <p id="runnerName">{bookData2.runnerName1}</p>
                     <ul >
                     {<li id="before" style = {{fontSize:'12px'}} className={`${bookpnl1 >= 0 ? "win":"lose"}`} >{bookpnl1 >= 0 ? parseFloat(bookpnl1).toFixed(2) : '('+ parseFloat(Math.abs(bookpnl1)).toFixed(2) +')'}</li>}
                     </ul>
                 </th>
                 <td class="" colspan="3">
                     <dl class="back-gradient">
                         <dd id="back_1" style={{cursor:'pointer'}} class=""><a>{bookData.runner1BackRate1}</a></dd>
                     </dl>
                 </td>
                 <td class="" colspan="3">
                     <dl class="lay-gradient">
                         <dd id="lay_1" style={{cursor:'pointer'}} class=""><a>{bookData.runner1LayRate1}</a></dd>
                     </dl>
                 </td>
             </tr>
             
             {((bookData.gameStatus != 2) ||  bookData.runner2Status == 2)  &&  <tr id="bookMakerSuspend_30194778_66628_197445" class="fancy-suspend-tr"  marketid="66628">
                 <th colspan=""></th>
                 <td class="fancy-suspend-td" colspan="6">
                    <div id="suspendClass" class="fancy-suspend"><span id="info">{bookData.gameStatus == 10 && bookData.runner2Status == 1? 'Ball Running' : 'Suspend'}</span></div>
                 </td>
             </tr>}

             <tr id="bookMakerSelection_30194778_66628_197445" style={{display: 'table-row'}}>
                 <th class="predict">
                     <p id="runnerName">{marketData.runnerName2}</p>
                     <ul >
                     {<li id="before" style = {{fontSize:'12px'}} className={`${bookpnl2 >= 0 ? "win":"lose"}`} >{bookpnl2 >= 0 ? parseFloat(bookpnl2).toFixed(2) : '('+ parseFloat(Math.abs(bookpnl2)).toFixed(2) +')'}</li>}
                     </ul>
                 </th>
                 <td class="" colspan="3">
                     <dl class="back-gradient">
                         <dd id="back_1" style={{cursor:'pointer'}} class=""><a>{bookData.runner2BackRate1}</a></dd>
                     </dl>
                 </td>
                 <td class="" colspan="3">
                     <dl class="lay-gradient">
                         <dd id="lay_1" style={{cursor:'pointer'}} class=""><a>{bookData.runner2LayRate1}</a></dd>
                     </dl>
                 </td>
              </tr>

        {bookData2.selectionIdTie && bookData2.selectionIdTie != ' ' &&  
              <React.Fragment>
        
        {((bookData.gameStatus != 2) ||  bookData.runner3Status == 2)  &&  <tr id="bookMakerSuspend_30194778_66628_197445" class="fancy-suspend-tr"  marketid="66628">
                 <th colspan=""></th>
                 <td class="fancy-suspend-td" colspan="6">
                     <div id="suspendClass" class="fancy-suspend"><span id="info">{bookData.gameStatus == 10 && bookData.runner3Status == 1? 'Ball Running' : 'Suspend'}</span></div>
                 </td>
             </tr>}
             
             <tr id="bookMakerSelection_30194778_66628_197445" style={{display: 'table-row'}}>
                 <th class="predict">
                     <p id="runnerName">The Draw</p>
                     <ul >
                     {<li id="before" style = {{fontSize:'12px'}} className={`${bookpnl3 >= 0 ? "win":"lose"}`} >{bookpnl3 >= 0 ? parseFloat(bookpnl3).toFixed(2) : '('+ parseFloat(Math.abs(bookpnl3)).toFixed(2) +')'}</li>}
                     </ul>
                 </th>
                 <td class="" colspan="3">
                     <dl class="back-gradient">
                         <dd id="back_1" style={{cursor:'pointer'}} class=""><a>{bookData.tieBackRate1}</a></dd>
                     </dl>
                 </td>
                 <td class="" colspan="3">
                     <dl class="lay-gradient">
                         <dd id="lay_1" style={{cursor:'pointer'}} class=""><a>{bookData.tieLayRate1}</a></dd>
                     </dl>
                 </td>
             </tr>
            </React.Fragment>}
              
              </tbody>
            </table>
         
         </div>}	







        
      {(marketData.eventType == '4' || bookData2.eventType == '4') && (marketData.matchStatus == 1 || bookData2.matchStatus == 1) && <div id="fancyBetTable_30194778" class="bets-wrap fancy_bet"  name="multiMarketItem">
         <div id="fancyBetHead" class="fancy-head">
             <h4 class="in-play">
                 {/* <!-- a id="multiMarketPin" class="add-pin" name="te" style={{cursor:'pointer'}} title="Add to Multi Markets"></a--> */}
                 <span id="headerName">Fancy Bet</span>
                    <a class="btn-fancybet_rules"></a>  
                     
             </h4>
         </div>
         <table id="fancyBetMarketList" class="bets" >
             <colgroup>
                 <col span="1" width="280"/>
                 <col span="1" width="70"/>
                 <col span="1" width="70"/>
                 <col span="1" width="70"/>
                 <col span="1" width="70"/>
                 <col span="1" width="70"/>
                 <col span="1" width="70"/>

                 <col span="1" width="70"/>
                 <col span="1" width="70"/>
                 <col span="1" width="70"/>
                 <col span="1" width="70"/>
             </colgroup>
             <tbody>
                <tr id="fancyBetSpecialBet" class="special_bet">
                     <td colspan="11">
                         <h3>
                         <a id="multiMarketPin" class="add-pin" title="Add to Multi Markets">Add Pin</a>
                         Fancy Bet

                        <dl class="fancy-info">
                          <a onClick = {()=>{setshowDownline(true);setpopup(3);}}  style ={{ padding: "0px 10px"}} id="marketDownline" class="btn-send">Declared Fancy</a>
                       </dl>
                         </h3>
                     </td>
                </tr>
                <tr class="bet-all"><td colspan="1"></td><td colspan="3">No</td><td colspan="3">Yes</td></tr> 
                
                
                 <tr id="suspendTemplate" class="fancy-suspend-tr" style={{display:'none'}}>
                     <th></th>
                     <td colspan="2"></td>
                     <td class="fancy-suspend-td" colspan="2">
                         <div id="suspendClass" class="fancy-suspend"><span id="info">Ball Running</span></div>
                     </td>
                     <td colspan="2"></td>
                 </tr>
                 
                 <tr id="delayTemplate" class="fancy-suspend-tr" style={{display:'none'}}>
                     <th></th>
                     <td colspan="2"></td>
                     <td class="fancy-suspend-td" colspan="2">
                         <div class="fancy-suspend"><span id="info">Delay Betting</span></div>
                     </td>
                     <td colspan="2"></td>
                 </tr>
                 
                 
             
             {fancyData.map(function(item,index){   

               return(
               <React.Fragment key = {index}> 
               {(item.gameStatus != 2) && <tr id="bookMakerSuspend_30194778_66628_197445"  class="fancy-suspend-tr"  marketid="66628">
                 <th colspan=""></th>
                 <td class="fancy-suspend-td" colspan="6">
                     <div id="suspendClass" style={{height:'46px'}} class="fancy-suspend"><span id="info">{item.gameStatus == 10 ? 'Ball Running' : 'Suspend'}</span></div>
                 </td>
               </tr>} 
               



               <tr  id="bookMakerSelection_30194778_66628_197444" style={{display: 'table-row'}}>
                 <th class="predict">
                     <p id="runnerName">{item.runnerName}</p>
                     <a id="fancyBetBookBtn" onClick = {()=>{setfancyName(item.runnerName);setfancySelection(item.selectionId); setshowDownline(true);setpopup(4);}} class="btn-book" style={{marginRight:'10px'}}>Book</a>
                 </th>

                 
                 
                 <td class="" colspan="3">
                     <dl class="lay-gradient">

                         <dd id="lay_1" class=""><a style = {{display:'block'}}>{item.layPrice ? item.layPrice : ' '}<span>{item.laySize ? item.laySize : ' '}</span></a></dd>
                         
                     </dl>
                 </td>
                 <td class="" colspan="3">
                     <dl class="back-gradient">
                         <dd id="back_1" style={{cursor:'pointer'}} class=""><a style = {{display:'block'}}>{item.backPrice ? item.backPrice : ' '}<span>{item.backSize ? item.backSize : ' '}</span></a></dd>
                     </dl>
                 </td>
                </tr>
                
                 </React.Fragment>   
               )})}
                 
                </tbody>

               </table>

              </div>}
                
             </div>
            
           </div>

          {/* <!-- Right Column --> */}
         <div class="col-right">
            <div class="slip-wrap no-open-bet_slip">    
          </div>
          </div>
         
         </div>
         {showDownline === true && popup === 1 && <DownlineLive  changeDownlineLive = {changeDownlineLive} eventId = {eventid} marketId =  {marketData.marketId} tie =  {marketData.selectionIdTie} marketname = {marketData.eventName + ' - Match Odds'}/>} 
         {showDownline === true && popup === 2 && <DownlineLive  changeDownlineLive = {changeDownlineLive} eventId = {eventid} marketId =  {bookData2.marketId} tie =  {bookData2.selectionIdTie} marketname = {bookData2.eventName + ' - BookMaker'}/> } 
         {showDownline === true && popup === 3 && <DeclaredFancy  changeDownlineLive = {changeDownlineLive} eventId = {eventid} marketname = {marketData.marketName ? marketData.eventName + ' - Declared Fancy' : bookData2.eventName + ' - Declared Fancy'}/>} 

         {showDownline === true && popup === 4 && <FancyBook  changeDownlineLive = {changeDownlineLive} eventId = {eventid} selectionId = {fancySelection} marketname = {marketData.marketName ? fancyName :fancyName}/>} 
         </React.Fragment>
         )
        }
         
         