import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Cookies from 'universal-cookie';
import moment from 'moment';
import { toast } from 'react-toastify'
import Transparent from './images/transparent.gif'
import Pagination from 'react-js-pagination';
import DatePicker from "react-datepicker";
import Loading from './images/loading40.gif'

import "react-datepicker/dist/react-datepicker.css";

const cookies = new Cookies();
window.market_day = 3;

toast.configure()

export default function Market() {

  const [marketList, setmarketList] = useState([]);
  const [agentList, setagentList] = useState([]);
  const [clientList, setclientList] = useState([]);
  const [eventType, seteventType] = useState('100');
  const [agentId, setagentId] = useState('100');
  const [sDate, setsDate] = useState(moment().format("YYYY-MM-DD"));
  const [eDate, seteDate] = useState(moment().add(1, 'days').format("YYYY-MM-DD"));
  const [startDate, setStartDate] = useState(moment().toDate());
  const [endDate, setendDate] = useState(moment().add(1, 'days').toDate());
  const [refresh, setrefresh] = useState(false);
  const [loading, setloading] = useState(true)


  var sum1 = 0, sum2 = 0, sum3 = 0, sum4 = 0, sum5 = 0;


  useEffect(() => {


    var ssid = cookies.get('sid');
    if (!ssid) return;


    axios.post('https://flagapi.in/agentapi/NewagentBelowDetailInfo', {
      sid: ssid

    })
      .then(result => {

        if (result.status === 200) {

          var arr = [];
          for (let key in result.data) {

            if (result.data.hasOwnProperty(key)) {
              result.data[key].user = key;
              arr.push(result.data[key]);


            }
          }
          setagentList(arr);


        }

      }

      ).catch(e => {
        //setIsError(true);
      });

    axios.post('https://flagapi.in/agentapi/newclientBelowDetailInfo', {
      sid: ssid

    })
      .then(result => {
        if (result.status === 200) {

          var arr = [];
          for (let key in result.data) {

            if (result.data.hasOwnProperty(key)) {
              result.data[key].user = key;
              arr.push(result.data[key]);
            }
          }

          setclientList(arr);


        }

      }

      ).catch(e => {
        //setIsError(true);
      });


  }, []);


  useEffect(() => {
    setTimeout(() => { setloading(false); }, 400);
  }, [])


  const getMarketList = () => {

    var ssid = cookies.get('sid');
    if (!ssid) return;

    var start = '';
    var end = '';

    if (window.market_day === 3) {
      start = sDate + ' ' + '09:00:00';
      end = eDate + ' ' + '08:59:00';
    }
    else if (window.market_day === 1) {
      var now = moment();
      start = now.startOf('day').format("YYYY-MM-DD HH:mm:ss");
      end = now.endOf('day').format("YYYY-MM-DD HH:mm:ss");

    }
    else if (window.market_day === 2) {
      var now = moment().subtract(1, 'days');
      start = now.startOf('day').format("YYYY-MM-DD HH:mm:ss");
      end = moment().endOf('day').format("YYYY-MM-DD HH:mm:ss");


    }
    setrefresh(true);
    axios.post('https://flagapi.in/agentapi/marketPl', {
      sid: ssid,
      startDate: start,
      endDate: end,
      agentId: agentId,
      sportType: eventType,

    })
      .then(result => {
        setrefresh(false);
        if (result.status === 200) {
          var res = [];
          for (let key in result.data) {

            if (result.data.hasOwnProperty(key)) {
              result.data[key].eventId = key;
              res.push(result.data[key]);

            }
          }

          if (res.length == 0) {
            toast.warn('No bets in this periods!', { position: toast.POSITION.TOP_CENTER })
          }

          setmarketList(res);

        }

      }

      ).catch(e => {
        //setIsError(true);
      });
  }

  const changeAgent = (e) => {
    var selectBox = document.getElementById("agents");
    var selectedValue = selectBox.options[selectBox.selectedIndex].value;
    setagentId(selectedValue);


  }

  const changeEvent = (e) => {
    var selectBox = document.getElementById("func_sports");
    var selectedValue = selectBox.options[selectBox.selectedIndex].value;
    seteventType(selectedValue);

  }







  return (
    <React.Fragment>

      {refresh && <div id="loading" class="loading-wrap" style={{}}>
        <ul class="loading">
          <li>
            <img src={Loading} />
          </li>
          <li>Loading...</li>
        </ul>
      </div>}


      {loading && <div id="loading" class="loading-wrap" style={{}}>
        <ul class="loading">
          <li>
            <img src={Loading} />
          </li>
          <li>Loading...</li>
        </ul>
      </div>}

      <div class="main_wrap" style={{ height: 'calc(100% - 105px)' }}>

        <h2>Profit/Loss Report by Market
        </h2>
        {/* <!-- function --> */}
        <div class="function-wrap">
          <ul class="input-list">
            <div id="statusCondition" style={{ display: 'none' }}>
              <li><label>Bet Status:</label></li>
              <li>
                <select name="betStatus" id="betStatus">
                </select>
              </li>
            </div>
            <li><label>Sports</label></li>
            <li>
              <select id="func_sports" onChange={(e) => { changeEvent(e); }}>
                <option value="100" selected="selected">All</option>
                <option value="4">CRICKET</option>
                <option value="1">SOCCER</option>
                <option value="2">TENNIS</option>
                <option value="5">BINARY</option>
                <option value="6">POLITICS</option>

              </select>
            </li>



            <li><label>Time Zone</label></li>
            <li>
              <select name="timezone" id="timezone">

                <option value="IST" selected="selected">IST(Bangalore / Bombay / New Delhi) (GMT+5:30)</option>

              </select>


            </li>


            <li>

            </li>

            <li><label>Period</label>
            </li>
            <li class="input-list" style={{ display: 'inline-flex' }}>

              <DatePicker
                selectsStart
                dateFormat="yyyy-MM-dd"
                selected={startDate}
                placeholderText="YYYY-MM-DD"
                className="cal-input"
                onChange={(date) => { setStartDate(date); setsDate(moment(date).format("YYYY-MM-DD")); }}
              />
              <input id="startTime" disabled="true" class="time-input disable" type="text" placeholder="09:00" maxlength="5" />

              <DatePicker
                selectsEnd
                dateFormat="yyyy-MM-dd"
                placeholderText="YYYY-MM-DD"
                selected={endDate}
                onChange={(date) => { setendDate(date); seteDate(moment(date).format("YYYY-MM-DD")); }}
                className="cal-input"
              />
              <input id="endTime" disabled="true" class="time-input disable" type="text" placeholder="08:59" maxlength="5" />


            </li>
            <li style={{ display: 'none' }}>(TimeZone:IST)</li>


            {/* <li><label>Agent/Player</label></li>
     <li>
     <select name="agent" id="agents" onChange = {(e)=>{changeAgent(e);}}>
        <option value="100" selected="selected">All</option> 
    {agentList.map((item,index)=>{
          var lev;
         
          if(item.level == 1){
            lev = 'SSS';
            
          }
          else if(item.level == 2){
           lev = 'SS';
           
          }
         else if(item.level == 3){
           lev = 'SUP';
          
          }
         else if(item.level == 4){
           lev = 'MA';
          
          }

         return(
         
          <option key = {index} value={item.user} >{lev+' - '+ item.user}</option>
          )})}  


        {clientList.map((item,index)=>{
          

         return(
         
          <option key = {index} value={item.user} >{'PL - '+item.user}</option>
          )})}  
        </select>

    
       </li> */}

          </ul>

          <ul class="input-list">
            <li><a id="today" onClick={() => { window.market_day = 1; getMarketList(); }} class="btn">Just For Today</a></li>
            <li><a id="yesterday" onClick={() => { window.market_day = 2; getMarketList(); }} class="btn">From Yesterday</a></li>
            <li style={{ display: 'none' }}><a id="last7days" class="btn">Last 7 days</a></li>
            <li style={{ display: 'none' }}><a id="last30days" class="btn">Last 30 days</a></li>
            <li style={{ display: 'none' }}><a id="last2months" class="btn">Last 2 Months</a></li>
            <li style={{ display: 'none' }}><a id="last3months" class="btn">Last 3 Months</a></li>
            <li><a id="getPL" onClick={() => { window.market_day = 3; getMarketList(); }} class="btn-send">Get P &amp; L</a></li>
          </ul>
        </div>
        {/* <!-- No Report Message --> */}
        <div id="noReportMessage"></div>




        {/* <!-- Loading Wrap --> */}
        <div id="loading" class="loading-wrap" style={{ display: 'none' }}>
          <ul class="loading">
            <li><img src="/images/loading40.gif" /></li>
            <li>Loading...</li>
          </ul>
        </div>

        {/* <!-- Message --> */}
        <div id="message" class="message-wrap success">
          <a class="btn-close">Close</a>
          <p></p>
        </div>



        {/* <!-- Report Table --> */}
        {marketList.length > 0 && <table id="matchTableTemplate" class="table-s" style={{}}>
          <tbody>
            <tr>

              <th width="" class="align-L">UID
              </th>
              <th width="12%">Match P/L
              </th>
              <th width="12%">Fancy P/L
              </th>
              <th width="12%">Fancy Stake
              </th>
              <th width="12%">Commission
              </th>
              <th width="12%" name="profitOrLossTh"> Net P/L
              </th>
              <th width="3%" id="currencyType_title" style={{ display: 'none' }}>Currency
              </th>
              <th width="5%" id="site_title" style={{ display: 'none' }}>Site
              </th>
              <th width="5%" id="onePT_title" style={{ display: 'none' }}>Percentage PT</th>
              <th width="5%" id="afterPT_title" style={{ display: 'none' }}>PSB After PT</th>
              <th width="5%" id="afterPTInEUR_title" style={{ display: 'none' }}>H.PT in EURO</th>
            </tr>

            {marketList.map((item, index) => {
              var matchName;

              if (item.eventType == '4') {
                matchName = 'CRICKET';
              } else if (item.eventType == '1') {
                matchName = 'SOCCER';
              }
              else if (item.eventType == '2') {
                matchName = 'TENNIS';
              }

              sum1 = sum1 + parseFloat(item.matchPL);
              sum2 = sum2 + parseFloat(item.sessionPL);
              sum3 = sum3 + parseFloat(item.sessionTran);
              sum4 = sum4 + parseFloat(item.comm);
              sum5 = sum5 + parseFloat(item.netPL);




              return (

                <tr key={index} id="matchRow0" style={{ display: "table-row" }}>


                  <td class="align-L">
                    <a id="_byMarket" class="expand-close"></a>
                    <a><span id="_eventType">{matchName}</span>
                      <img class="fromto" src={Transparent} />
                      <strong id="_eventName">{item.eventName}</strong>
                    </a>
                  </td>


                  <td id="matchSelection" style={(item.matchPL >= 0) ? { color: '' } : { color: 'red' }}>{item.matchPL >= 0 ? parseFloat(item.matchPL).toFixed(2) : '(' + parseFloat(Math.abs(item.matchPL)).toFixed(2) + ')'}</td>
                  <td id="matchSelection" style={(item.sessionPL >= 0) ? { color: '' } : { color: 'red' }}>{item.sessionPL >= 0 ? parseFloat(item.sessionPL).toFixed(2) : '(' + parseFloat(Math.abs(item.sessionPL)).toFixed(2) + ')'}</td>
                  <td id="fancyStake">{item.sessionTran}</td>
                  <td id="commStake">{parseFloat(item.comm).toFixed(2)}</td>


                  <td id="matchSelection" style={(item.netPL >= 0) ? { color: '' } : { color: 'red' }}>{item.netPL >= 0 ? parseFloat(item.netPL).toFixed(2) : '(' + parseFloat(Math.abs(item.netPL)).toFixed(2) + ')'}</td>



                  <td id="currencyType" style={{ display: 'none' }}>USD</td>
                  <td id="site" style={{ display: 'none' }}>SKYEXCHANGE</td>
                  <td id="onePT" style={{ display: 'none' }}>0.25</td>
                  <td id="afterPT" style={{ display: 'none' }}>25.00</td>
                  <td id="afterPTInEUR" style={{ display: 'none' }}>1.99</td>
                </tr>
              )
            })}

            <tr id="tempTotalTr" class="total">
              <td class="align-L">Total</td>
              <td id="_totalStake" style={(sum1 >= 0) ? { color: '' } : { color: 'red' }} >{sum1 >= 0 ? parseFloat(sum1).toFixed(2) : '(' + parseFloat(Math.abs(sum1)).toFixed(2) + ')'}</td>
              <td id="_totalProfitLoss" style={(sum2 >= 0) ? { color: '' } : { color: 'red' }}>{sum2 >= 0 ? parseFloat(sum2).toFixed(2) : '(' + parseFloat(Math.abs(sum2)).toFixed(2) + ')'}</td>
              <td id="_totalProfitLossDownLine">{sum3}</td>
              <td id="_totalPayout1">{sum4.toFixed(2)}</td>
              <td id="_totalTax1" style={(sum5 >= 0) ? { color: '' } : { color: 'red' }}>{sum5 >= 0 ? parseFloat(sum5).toFixed(2) : '(' + parseFloat(Math.abs(sum5)).toFixed(2) + ')'}</td>


            </tr>

          </tbody>
        </table>}
        {/* <!-- Report Table --> */}

      </div>
    </React.Fragment>
  )
}
