import React, { useState,useEffect } from 'react'
import axios from 'axios';
import Cookies from 'universal-cookie';
import Transparent from './images/transparent.gif'
import { Link } from 'react-router-dom'
import Loading from './images/loading40.gif'

const cookies = new Cookies();

export default function RiskManagement() {
    const [cricketList,setcricketList] = useState([]);
    const [soccerList,setsoccerList] = useState([]);
    const [tennisList,settennisList] = useState([]);
    const [refresh,setrefresh] = useState(true);
    const [reload,setreload] = useState(true);



    useEffect(() => {
     
        var ssid = cookies.get('sid');
        if(!ssid) return;
        setrefresh(true);
        
        axios.post(' https://flagapi.in/agentapi/newliveMatchList',{
               sid:ssid,
               eventType:4
              })
              .then(result => {
                  setrefresh(false);
                  var res = [];
                  if(result.status === 200){
                    for (let key in result.data) { 
                        
                        if (result.data.hasOwnProperty(key)) 
                        { 
                            result.data[key].eventId = key;
                            res.push(result.data[key]);
                            
                            
                        } 
                     }
                    setcricketList(res);
                  
                  }

                
         
                 }
                     
               ).catch(e => {
                  //setIsError(true);
              });

        axios.post(' https://flagapi.in/agentapi/newliveMatchList',{
              sid:ssid,
              eventType:1
             })
             .then(result => {
                
                if(result.status === 200){
                    var res = [];
                   
                       for (let key in result.data) { 
                          
                          if (result.data.hasOwnProperty(key)) 
                          { 
                              result.data[key].eventId = key;
                              res.push(result.data[key]);
                                                           
                          } 
                       }
                     setsoccerList(res);
                   
                   }
          
                  }
                      
               ).catch(e => {
                   //setIsError(true);
            });

        axios.post(' https://flagapi.in/agentapi/newliveMatchList',{
                sid:ssid,
                eventType:2
            })
              .then(result => {
                  
                  if(result.status === 200){
                    var res = [];
                   
                    for (let key in result.data) { 
                       
                       if (result.data.hasOwnProperty(key)) 
                       { 
                           result.data[key].eventId = key;
                           res.push(result.data[key]);
                                                        
                       } 
                    }
                     settennisList(res);
                     
                     }

                     
            
                    }
                        
                 ).catch(e => {
                     //setIsError(true);
            });
     
   
  

     }, [reload])
      



return (
        
<React.Fragment>
  <div class="main_wrap">
                
  {refresh && <div id="loading" class="loading-wrap" style={{}}>
		<ul class="loading">
			<li>
				<img src={Loading}/>
			</li>
			<li>Loading...</li>
		   </ul>
	      </div>}
    
    {/* <!-- Message --> */}
    <div id="message" class="message-wrap success">
      <a class="btn-close">Close</a>
      <p></p>
    </div>
    
        {/* <!-- Report Table --> */}
    <div class="total_all">
        <h2>Risk Management Summary
        </h2>
        <a class="btn_replay" id="refresh_Top_Racing" style={{display:'none'}}><img src={Transparent}/></a>
    </div>



    <div class="match-wrap">
        <div class="total_all">
            <h2>Cricket</h2>
            <a class="btn_replay" onClick = {()=>{setreload(!reload);}} id="refresh_Match_Odds"><img src={Transparent}/></a>
            <a class="btn_replay"  id="downloadFile_Match_Odds" style={{display: 'none',width: "70px"}}>Download</a>
        </div>
        <table class="table01 risk_matchodd">
            <tbody><tr>
                <th width="10%" class="align-L" rowspan="2">Sports</th>
                <th width="8%" class="align-L" rowspan="2">Market Date</th>
                <th class="align-L" rowspan="2">Event/Market Name</th>
                <th width="21%" class="align-C border-l bg-yellow" colspan="3">Player P/L</th>
                {/* <th width="6%" class="align-C border-l" rowspan="2">Downline P/L</th> */}
            </tr>
            <tr>
                <th width="7%" class="border-l bg-yellow">1</th>
                <th width="7%" class="bg-yellow">X</th>
                <th width="7%" class="bg-yellow">2</th>
            </tr>
    
            </tbody>

        {cricketList.map((item,index)=>{   
    

           return(   
            <tbody key={index} id="content_MATCH_ODDS_DRAW"><tr id="tempTr_MATCH_ODDS_DRAW" class="border-t">
			<td class="align-L" rowspan="1"  id="0_1"><a id="eventType">Cricket</a></td>
			<td class="align-L border-l" rowspan="1" id="1_1" >{item.date}</td>
			<td class="align-L border-l">
				{/* <a href="javascript:void(0);" class="btn open-odds" id="showOddsBtn"  name="oddsBtn_30180016">Open</a> */}
				<Link to={`/Runningmarketanlysis/${item.eventId}`} id="marketPath">
					<strong id="eventName">{item.eventName}</strong>
					<img class="fromto" src={Transparent}/>
					<span id="marketName">Match Odds</span>
				</Link>
			</td>
			<td class="border-l"><a className={`${item.teamA_total >= 0 ? "green":"red"}`} id="selection_exposure_1">{item.teamA_total >= 0 ? parseFloat(item.teamA_total).toFixed(2) : '('+parseFloat(Math.abs(item.teamA_total)).toFixed(2) +')'}</a></td>
			<td><a className={`${item.draw_total >= 0 ? "green":"red"}`}  id="selection_exposure_3">{item.draw_total >= 0 ? parseFloat(item.draw_total).toFixed(2) : '('+parseFloat(Math.abs(item.draw_total)).toFixed(2) +')'}</a></td>
			<td><a class=""  id="selection_exposure_2"><span className={`${item.teamB_total >= 0 ? "green":"red"}`}>{item.teamB_total >= 0 ? parseFloat(item.teamB_total).toFixed(2) : '('+parseFloat(Math.abs(item.teamB_total)).toFixed(2) +')'}</span></a></td>
			{/* <td class="border-l"><a href="javascript:void(0);" class="btn" id="view">View</a></td> */}
            
		  </tr>
         <tr id="expand_30180016" class="expand" style={{display: 'none'}}>
			<td class="border-l align-L" colspan="4">
				<img class="expand-arrow" src={Transparent}/>
				<iframe src="" frameborder="0" height="197" scrolling="no"></iframe>
				<div class="risk-message" id="risk_message" style={{display: 'none'}}></div>
			</td>
			<td width="80" class="border-l"></td>
		 </tr></tbody>

        )})}

   

        </table>
    </div>
    


    <div class="match-wrap">
        <div class="total_all">
            <h2>Soccer</h2>
            <a class="btn_replay" onClick = {()=>{setreload(!reload);}} id="refresh_Match_Odds"><img src={Transparent}/></a>
            <a class="btn_replay"  id="downloadFile_Match_Odds" style={{display: 'none',width: "70px"}}>Download</a>
        </div>
        <table class="table01 risk_matchodd">
            <tbody><tr>
                <th width="10%" class="align-L" rowspan="2">Sports</th>
                <th width="8%" class="align-L" rowspan="2">Market Date</th>
                <th class="align-L" rowspan="2">Event/Market Name</th>
                <th width="21%" class="align-C border-l bg-yellow" colspan="3">Player P/L</th>
                {/* <th width="6%" class="align-C border-l" rowspan="2">Downline P/L</th> */}
            </tr>
            <tr>
                <th width="7%" class="border-l bg-yellow">1</th>
                <th width="7%" class="bg-yellow">X</th>
                <th width="7%" class="bg-yellow">2</th>
            </tr>
    
            </tbody>


          {soccerList.map((item,index)=>{ 
                
            return(    
            <tbody key = {index} id="content_MATCH_ODDS_DRAW"><tr id="tempTr_MATCH_ODDS_DRAW" class="border-t">
			<td class="align-L" rowspan="1"  id="0_1"><a id="eventType" href="javascript:void(0);">Soccer</a></td>
			<td class="align-L border-l" rowspan="1" id="1_1" >{item.date}</td>
			<td class="align-L border-l">
				{/* <a href="javascript:void(0);" class="btn open-odds" id="showOddsBtn"  name="oddsBtn_30180016">Open</a> */}
				<Link to={`/Runningmarketanlysis/${item.eventId}`} id="marketPath">
					<strong id="eventName">{item.eventName}</strong>
					<img class="fromto" src={Transparent}/>
					<span id="marketName">Match Odds</span>
				</Link>
			</td>
			<td class="border-l"><a className={`${item.teamA_total >= 0 ? "green":"red"}`}  id="selection_exposure_1">{item.teamA_total >= 0 ? parseFloat(item.teamA_total).toFixed(2) : '('+parseFloat(Math.abs(item.teamA_total)).toFixed(2) +')'}</a></td>
			<td><a className={`${item.draw_total >= 0 ? "green":"red"}`} id="selection_exposure_3">{item.draw_total >= 0 ? parseFloat(item.draw_total).toFixed(2) : '('+parseFloat(Math.abs(item.draw_total)).toFixed(2) +')'}</a></td>
			<td><a  id="selection_exposure_2"><span className={`${item.teamB_total >= 0 ? "green":"red"}`}>{item.teamB_total >= 0 ? parseFloat(item.teamB_total).toFixed(2) : '('+parseFloat(Math.abs(item.teamB_total)).toFixed(2) +')'}</span></a></td>
			{/* <td class="border-l"><a href="javascript:void(0);" class="btn" id="view">View</a></td> */}
		  </tr>
          <tr id="expand_30180016" class="expand" style={{display: 'none'}}>
			<td class="border-l align-L" colspan="4">
				<img class="expand-arrow" src={Transparent}/>
				<iframe src="" frameborder="0" height="197" scrolling="no"></iframe>
				<div class="risk-message" id="risk_message" style={{display: 'none'}}></div>
			</td>
			<td width="80" class="border-l"></td>
		  </tr>
         </tbody>
         )})}

        </table>
    </div>




    <div class="match-wrap">
        <div class="total_all">
            <h2>Tennis</h2>
            <a class="btn_replay" onClick = {()=>{setreload(!reload);}} id="refresh_Match_Odds"><img src={Transparent}/></a>
            <a class="btn_replay" href="javascript:void(0);" id="downloadFile_Match_Odds" style={{display: 'none',width: "70px"}}>Download</a>
        </div>
        <table class="table01 risk_matchodd">
            <tbody><tr>
                <th width="10%" class="align-L" rowspan="2">Sports</th>
                <th width="8%" class="align-L" rowspan="2">Market Date</th>
                <th class="align-L" rowspan="2">Event/Market Name</th>
                <th width="21%" class="align-C border-l bg-yellow" colspan="3">Player P/L</th>
                {/* <th width="6%" class="align-C border-l" rowspan="2">Downline P/L</th> */}
            </tr>
            <tr>
                <th width="7%" class="border-l bg-yellow">1</th>
                <th width="7%" class="bg-yellow">X</th> 
                <th width="7%" class="bg-yellow">2</th>
            </tr>
    
            </tbody>

            
         {tennisList.map((item,index)=>{ 

            return(    
            <tbody key = {index} id="content_MATCH_ODDS_DRAW"><tr id="tempTr_MATCH_ODDS_DRAW" class="border-t">
			<td class="align-L" rowspan="1"  id="0_1"><a id="eventType">Tennis</a></td>
			<td class="align-L border-l" rowspan="1" id="1_1" >{item.date}</td>
			<td class="align-L border-l">
				{/* <a href="javascript:void(0);" class="btn open-odds" id="showOddsBtn"  name="oddsBtn_30180016">Open</a> */}
				<Link to={`/Runningmarketanlysis/${item.eventId}`} id="marketPath">
					<strong id="eventName">{item.eventName}</strong>
					<img class="fromto" src={Transparent}/>
					<span id="marketName">Match Odds</span>
				</Link>
			</td>
			<td class="border-l"><a className={`${item.teamA_total >= 0 ? "green":"red"}`} id="selection_exposure_1">{item.teamA_total >= 0 ? parseFloat(item.teamA_total).toFixed(2) : '('+parseFloat(Math.abs(item.teamA_total)).toFixed(2) +')'}</a></td>
		    <td><a className={`${item.draw_total >= 0 ? "green":"red"}`}  id="selection_exposure_3">0</a></td> 
			<td><a   id="selection_exposure_2"><span className={`${item.teamB_total >= 0 ? "green":"red"}`}>{item.teamB_total >= 0 ? parseFloat(item.teamB_total).toFixed(2) : '('+parseFloat(Math.abs(item.teamB_total)).toFixed(2) +')'}</span></a></td>
			{/* <td class="border-l"><a href="javascript:void(0);" class="btn" id="view">View</a></td> */}
		   </tr>
           <tr id="expand_30180016" class="expand" style={{display: 'none'}}>
			<td class="border-l align-L" colspan="4">
				<img class="expand-arrow" src={Transparent}/>
				<iframe src="" frameborder="0" height="197" scrolling="no"></iframe>
				<div class="risk-message" id="risk_message" style={{display: 'none'}}></div>
			</td>
			<td width="80" class="border-l"></td>
		   </tr>
            </tbody>
            )})}

          </table>
          </div>    
        </div>
      </React.Fragment>
     )
   }
